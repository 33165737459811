.themes-main{
  width: 100%;
  height: 97vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.themes-pages{
  width: 12%;
  background-color: #333333;
  height: 100%;
}

.themes-themes{
  width: 12%;
  background-color: #424242;
  height: 100%;
}

.themes-htmls{
  flex: 1;
  height: 100%;
  background-color: #696969;
}

.page:hover, .theme:hover{
  cursor: pointer;
}

.page, .theme{
  margin: 10px;
  padding: 10px;
  background-color: #004d71;
}

.page.active, .theme.active{
  background-color: #6bc4e8;
}

.htmls{
  display: grid;
  height: 90%;
  justify-items: center;
}

.iphoneView{
  aspect-ratio: 9/19.5;
  overflow: hidden;
  background-color: white;
  max-height: 100%;
  width: auto;
  margin-top: 5%;
}