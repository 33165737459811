
body{
    background-color: #222;
    color: #fff;
    position: relative;
}


.table{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.headers{
    font-weight: bold;
    border-bottom: 1px white solid;
    padding-bottom: 5px;
}

.headers, .body{
    width: 100%;
}

.row{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 1px 0px;
    opacity: 0.8;
    transition: all 0.1 ease-in-out;
    border-bottom: #222 1px solid;
}

.row:nth-child(2n){
    background-color: #ffffff11;
}

.row.marked{
    background-color: #474747;
}

.row:hover{
    opacity: 1;
    cursor: pointer;
}

.Pendiente_Terceros_Externo{
    background-color: #854444;
}

.Pendiente_Terceros_Interno{
    background-color: #b17a46;
}

.Cierre_Técnico{
    background-color: #438f43;
}

.Pendiente_Respuesta_de_Usuario{
    background-color: #b17a46;
}

.Asignado_al_Técnico{
    font-weight: bold;
    background-color: #ffffff00;
}

.Pendiente_Sistemas{
    background-color: #8098db;
}

.cell{
    text-align: left;
    padding: 5px 10px;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.cell.NUMERO_PEDIDO{
    width: 170px;
}

.cell.FECHA_COMPRA{
    width: 120px;
}

.body .cell.Title{
    text-align: left;
}

.cell.MARCA{
    width: 110px;
}

.cell.LOGÍSTICO{
    width: 100px;
}

.cell.TIENDA{
    width: 60px;
}

.cell.price{
    width: 210px;
}

.cell.Priority{
    width: 80px;
}

.cell.Marca{
    width: 10%;
}

.cell.comment{
    flex-grow: 1;
}

#contextMenu{
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.contextMenuItem{
    background-color: #222;
    border: solid 1px #333;
    padding: 5px;
    width: 100px;
}

.contextMenuItem:hover{
    background-color: #3a3a3a;
    cursor: pointer;
}

#searchBar{
    position: absolute;
    top: 5px;
    left: 25%;
    width: 50%;
    height: 30px;
}

#searchBar.show{
    opacity: 1;
    z-index: 99;
}

#searchBar.hide{
    opacity: 0;
    z-index: -1;
}

#seachBarInput{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 3px;
    font-size: 1.3rem;
}

#searchBarInput:focus{
    outline: none;
}

#summaryDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #333;
    top: 0%;
    left: 100%;
    opacity: 1;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

#summaryDiv.opened{
    left: 0%;
}

.toggleSummaryDiv{
    position: absolute;
    top: calc(50% - 50px);
    left: -30px;
    width: 30px;
    height: 100px;
    background-color: #333;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.closeBtnDiv{
    position: absolute;
    top: 40px;
    left: 10px;
    cursor: pointer;
}

.summaryContent{
    padding-top: 50px;
    justify-content: top;
    align-items: center;
    display: flex;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 50px;
}

.summaryBrand{
    width: 300px;
    text-align: left;
    margin-bottom: 40px;
}

.summaryBrandTitle{
    font-weight: bold;
    text-align: center;
}

.summaryBrandShop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.summaryBrandShop.zeroSales{
    color: #ff636355;
}

.summaryBrandShop.sales{
    color: #58cf58;
}

.summaryBrandShop:nth-child(2n){
    background-color: #ffffff11;
}

.summaryBrandTotal{
    text-align: right;
}

.summaryBottomSpacer{
    margin-bottom: 150px
}

.filesChecklist{
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 99;
    width: 30%;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fileItem{
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileIcon{
    margin-right: 10px;
}

.fileName{
    margin-bottom: 5px;
}

.fileName.checked{
    color: green;
}

.table.static{
    overflow-y: hidden;
}

.exportBtnDiv{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
}

.exportBtn{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #438f43;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.exportBtn:hover{
    background-color: #377537;
}