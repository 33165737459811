.ticket2HeaderBar{
  height: 30px;
  background-color: #44444420;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.modeDiv{
  display: flex;
}

.modeText{
  padding-right: 20px;
}

.modeBtn{
  display: flex;
}

.modeOption{
  padding-left: 10px;
  padding-right: 10px;
}

.sincDiv{
  display: flex;
}

.sincBtn{
  padding-left: 10px;
}

.loggedInUserSysAid{
  border-radius: 5px;
  padding: 2px;
  color: black;
  cursor: pointer;
}

.sysAidLoginModal{
  display: none;
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.sysAidLoginModal.open{
  display: flex;
}

.sysaidLoginBtn{
  background-color: green;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}