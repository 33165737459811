
body{
    background-color: #222;
    color: #fff;
    position: relative;
}


.mytable, .customsTable{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    height: calc(100vh-100px);
}

.myheaders{
    font-weight: bold;
    border-bottom: 1px rgb(67, 67, 67) solid;
    padding-bottom: 0px;
    margin-bottom: 5px;
}

.myheaders, .mybody{
    width: 100%;
}

.mybody > h5{
    text-align: left;
    margin-left: 10px;
    color: #438f43
}

.myrow{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 1px 0px;
    opacity: 0.8;
    transition: all 0.1 ease-in-out;
    border-bottom: #222 1px solid;
}

.myrow.marked{
    background-color: #474747;
}

.myrow:hover{
    opacity: 1;
    cursor: pointer;
}

.Pendiente_Terceros_Externo{
    background-color: #854444;
}

.Pendiente_Terceros_Interno{
    background-color: #b17a46;
}

.Cierre_Técnico{
    background-color: #438f43;
}

.Pendiente_Respuesta_de_Usuario{
    background-color: #b17a46;
}

.Asignado_al_Técnico{
    font-weight: bold;
    background-color: #ffffff00;
}

.Pendiente_Sistemas{
    background-color: #8098db;
}

.cell{
    text-align: left;
    padding: 5px 10px;
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
}

.cell.code{
    width: 50px;
}

.cell.Title{
    width: 20%;
}

.body .cell.Title{
    text-align: left;
}

.cell.State{
    width: 20%;
}

.cell.User{
    width: 15%;
}

.cell.Type{
    width: 50px;
}

.cell.Subcategory{
    width: 120px;
}

.cell.Priority{
    width: 100px;
}

.cell.Marca{
    width: 10%;
}

.cell.comment{
    flex-grow: 1;
}

#contextMenuGroups{
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: auto;
    z-index: -1;
    opacity: 0;
    padding: 30px;
}

.contectGroupsTitle{
    margin-bottom: 20px;
}

.contectGroupsGroup{
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

.contectGroupsGroup:hover{
    background-color: #ffffff29;
}

#contextMenuGroups.visible{
    z-index: 110;
    opacity: 1;
}

#contextMenu{
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.groupMenu{
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #333;
}

.groupMenu.visible{
    opacity: 1;
    z-index: 99;
}

.contextMenuItem{
    background-color: #222;
    border: solid 1px #333;
    padding: 5px;
    width: 150px;
}

.contextMenuItem.danger{
    color: white;
    background-color: #a70505;
}

.contextMenuItem:hover{
    background-color: #3a3a3a;
    cursor: pointer;
}

.contextMenuItem.danger:hover{
    color: #a70505;
    background-color: #3a3a3a;
}

#searchBar{
    position: absolute;
    top: 5px;
    left: 25%;
    width: 50%;
    height: 30px;
}

#searchBar.show{
    opacity: 1;
    z-index: 99;
}

#searchBar.hide{
    opacity: 0;
    z-index: -1;
}

#seachBarInput{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 3px;
    font-size: 1.3rem;
}

#searchBarInput:focus{
    outline: none;
}

.groupsTitleH5{
    position: relative;
}

.newGroupButton{
    position: absolute;
    top: 2px;
    left: 5%;
    background-color: transparent;
    color: #ffffff47;
    outline: none;
    border: none;
    margin-left: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.newGroupButton:hover{
    color: white;
}

.newGroupForm{
    position: fixed;
    top: 10%;
    left: 20%;
    width: 60%;
    background-color: #333;
    z-index: 99;
    padding: 15px;
}

.closeButtonDiv{
    position: absolute;
    top: 0px;
    right: 0px;
}

.closeButton{
    padding: 1px;
    border-radius: 10px;
    width: 50px;
    font-size: 2rem;
    cursor: pointer;
}

.newGroupNameInput{
    background-color: transparent;
    color: white;
    border: none;
    outline: none;
    border-bottom: solid 1px #747474;
    width: 100%;
    font-size: 2rem;
    text-align: center;
}

.newGroupColorDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 0px;
}

.newGroupCreateButtonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.newGroupCreateButton{
    padding: 10px;
    cursor: pointer;
    border: solid 1px white;
    border-radius: 15px;
    width: 25%;
    min-width: 200px;
}

.newGroupCreateButton.valid{
    color: black;
    background-color: white;
}

.removeGroupCloseButton{
    display: inline-block;
    margin-left: 10px;
    position: relative;
    opacity: 0;
    color: red;
    cursor: pointer;
    padding: 2px;
}

.groupButton:hover .removeGroupCloseButton{
    opacity: 1;
}

.customForm{
    position: fixed;
    z-index: 199;
    background-color: #222;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.customCloseBtnDiv{
    position: absolute;
    left: 0px;
    right: 0px;
    width: 5%;
    height: 35px;
}

.customCloseBtn{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.customRow{
    width: 90vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.customInput{
    padding: 5px;
    background-color: #414040;
    color: white;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
}

select.customInput{
    padding: 7px;
}

.customTitle{
    flex-grow: 1;
    max-width: 79%;
    text-align: left;
    padding-left: 10px;
}

.customTextArea{
    width: 90vw;
    height: 70vh;
    padding: 5px;
    background-color: #414040;
    color: white;
    border: none;
    outline: none;
    border-radius: 4px;
}

.customSubmitBtn{
    border: none;
    border-radius: 4px;
    background-color:  #36fe7c;
}