.App {
  text-align: center;
  height: 50%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.groupButton{
  border: none;
  color: white;
  padding: 5px;
  background-color: #00000000;
  width: 100%;
  outline: none;
}

.logoutButtonDiv{
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
}

.logoutButton{
  transition: all 0.3s ease-in-out;
  color: #ffffff66;
  padding: 5px 20px;
  cursor: pointer;
}

.logoutButton:hover{
  color: #fff;
}

#navList{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  text-decoration: none;
  list-style-type: none;
}

#navList li{
  color: white;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
}

#navList li a{
  color: white;
  text-decoration: none;
}

#mainNavList{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  text-decoration: none;
  list-style-type: none;
  height: 50%;
  width: 100%;
}

#mainNavList li{
  color: white;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px white;
  border-radius: 25px;
  width: 30%;
  margin: 10px;
  height: 30vh;
  background-color: #2e2f33;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mainNavListItem{
  color: white;
  font-size: 2.5rem;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainNavListItem:hover{
  color: white;
}

nav{
  position: relative;
}

.logoutDiv{
  position: absolute;
  top: 0px;
  right: 20px;
}

.logoutBtn{
  height: 100%;
  cursor: pointer;
}