.TS-item{
  border: solid 1px black;
  padding: 20px;
}

.TS-logo{
  width: 500px;
}

.TS-pedido{
  text-align: left;
  width: 50%;
  margin: auto;
  border: solid 1px black;
  padding: 10px;
}

@media print{

  body{
    color: black;
  }

  #navList{
    visibility: hidden;
  }
  
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .TS-item { break-inside: avoid; } /* page-break-after works, as well */

}